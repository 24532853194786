import { MembershipType } from '@aurora/shared-generated/types/graphql-schema-types';
import type { NodeTitleFragment } from '@aurora/shared-generated/types/graphql-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import Icons from '../../../icons';
import { IconColor, IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';
import useTranslation from '../../useTranslation';
import localStyles from './NodeTitle.module.pcss';

interface Props {
  /**
   * The node to display the description for.
   */
  node: NodeTitleFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * membership type
   */
  membershipType?: MembershipType;
  /**
   * should display lock icon adjacent to the title
   */
  showLockIcon?: boolean;
  /**
   * Node title snippet to be highlighted (to be only used for displaying search results)
   */
  titleSearchSnippet?: string;
  /**
   * if true, then 'Community' is displayed instead of the community name.
   */
  isCommunity?: boolean;
}

/**
 * Displays the node title with optional display of node icon.
 *
 * @author Adam Ayres
 */
const NodeTitle: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  as: Component = 'h5',
  className,
  membershipType,
  showLockIcon,
  titleSearchSnippet,
  isCommunity = false
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.NODE_TITLE);

  if (textLoading) {
    return null;
  }

  const showMembershipLockIcon =
    membershipType && membershipType !== MembershipType.Open && showLockIcon;

  const nodeTitle = isCommunity ? NodeType.COMMUNITY : node.title;

  return (
    <Component className={cx(className, 'lia-title-and-icon h5')} data-testid="NodeTitle">
      {titleSearchSnippet ? (
        <p
          className={cx('lia-g-mb-0')}
          dangerouslySetInnerHTML={{ __html: titleSearchSnippet }}
        ></p>
      ) : (
        formatMessage('nodeTitle', { nodeTitle: nodeTitle })
      )}
      {showMembershipLockIcon && (
        <Icon
          size={IconSize.PX_14}
          icon={Icons.LockIcon}
          className={cx('lia-lock-icon')}
          color={IconColor.GRAY_700}
        />
      )}
    </Component>
  );
};

export default NodeTitle;
